import React, { useEffect } from "react";

import WorkTile, { Entry } from "../components/WorkTile";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const WorkPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, []);

  const entries: Entry[] = [
    {
      title: "Senior Developer - Web & Group Lead",
      company: "Plus One Robotics",
      content:
        "I am a hybrid between people manager and software architect, I work on all web based products the company offers. I spend about 50% of my time people/project managing, 30% architecting & 20% coding. As it stands today, I manage a team of 3 devs and growing. I architect, plan and implement features. Out of all the companies I've worked for, this is by far the most interesting and engaging. Like just a few days ago, I came to the realization that I get to do some things that some devs dream about. I write software that runs in the cloud on vms and software that interfaces with PLCs which are just about as physical as you can get.",
      start: "2020",
      end: "Present",
      link: "https://www.plusonerobotics.com",
    },
    {
      title: "Web Developer",
      company: "Pawtify",
      content:
        "Worked part-time as a member of a city funded project meant to match prospective adopters of shelter animals. I mostly focused on developing the surrounding infrastructure of the web app.",
      start: "2020",
      end: "2020",
      link: "https://www.facebook.com/pawtifysa/",
    },
    {
      title: "Independent Contractor - Web Developer",
      company: "Self Employed",
      content:
        "Worked for various enterprises in various technologies after completing my contract with the Southwest Research Institute. Consulted for Brokerage Engine as a subject matter expert and assisted in the transition/takeover of a new CTO",
      start: "2019",
      end: "2019",
    },
    {
      title: "Contract Programmer",
      company: "Southwest Research Institute",
      content:
        "Consulted for the Ocean Simulation Laboratory to create a test result recording & ISO report generation web application. Worked with engineers and technicians to better accommodate increasing demand for services. Completed a 6 month contract in 4 months meeting both technical and client requirements. Fully delivered a full stack application from concept to full adoption.",
      start: "2019",
      end: "2019",
      link: "https://www.swri.org/",
    },
    {
      title: "Web Developer",
      company: "Brokerage Engine",
      content:
        "I was responsible for implementing and maintaining features for a real estate transaction calculation system used by multi-million dollar businesses. Coordinated and prioritized tasks to be completed using both agile and extreme programming principles and values. Architected systems from concept to adoption using both TDD and BDD in order to ensure maintainability of code in the future.",
      start: "2018",
      end: "2019",
      link: "https://www.brokerageengine.com/",
    },
    {
      title: "Independent Contractor - Web Developer",
      company: "Self Employed",
      content:
        "Began establishing myself on various contract sites like Upwork where I completed several contracts for web design, web development & minor web tasks.",
      start: "2017",
      end: "2018",
    },
    {
      title: "Wireless Sales Lead",
      company: "MarketSource Inc.",
      content:
        "Handled cellular and other wireless electronics sales in a highly competitive environment. Led, trained and mentored new employees in best practices and situations encountered with different customers.",
      start: "2015",
      end: "2017",
      link: "https://www.marketsource.com/",
    },
    {
      title: "Infantry Fireteam Leader",
      company: "US Army",
      content:
        "Senior gunner within an infantry platoon with an expertise in using the Bradley Infantry Fighting Vehicle and other wheeled vehicles. Led three subordinates directly and was often used as one of the main trainers for the company. Worked with senior leadership to develop practical solutions to common problems in both the maintenance and operation of these vehicles. Those solutions were later made into standard operating procedures for the battalion.",
      start: "2010",
      end: "2014",
      link: "https://www.goarmy.com/",
    },
  ];

  return (
    <div data-testid="work-page" className="animate-fade-in flex flex-col">
      <article className="flex flex-col justify-start">
        {entries.map((entry, idx) => (
          <div className="mt-1 md:h-screen-25 md:min-h-13" key={idx}>
            <WorkTile entry={entry} />
            {/* spacer */}
            <div className="flex bg-green-400 h-0.5 justify-center md:opacity-0 mt-3 mb-3" />
          </div>
        ))}
      </article>
    </div>
  );
};

export default WorkPage;
