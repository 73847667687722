import React from "react";

export type Entry = {
  degree: string;
  type: string;
  schoolName: string;
  start: string;
  end: string;
  description: string;
  link?: string;
};

export type EducationTileProps = {
  entry: Entry;
};

const EducationTile: React.FC<EducationTileProps> = ({ entry }) => {
  return (
    <article className="flex flex-col md:flex-row justify-between">
      <div className="md:w-1/2">
        <h3 className="text-xl">{entry.degree}</h3>
        <div className="text-lg">{entry.type}</div>
        <div className="text-lg">
          {entry.link ? (
            <a
              data-testid="school-link"
              href={entry.link}
              aria-label="to school site"
              target="_blank"
              className="underline hover:text-gray-400 transition ease-in-out duration-300"
            >
              <span>{entry.schoolName}</span>
            </a>
          ) : (
            <span>{entry.schoolName}</span>
          )}
        </div>
        <div>
          {entry.start} {"->"} {entry.end}
        </div>
      </div>

      <div className="mt-1 md:mt-0 md:w-1/2">{entry.description}</div>
    </article>
  );
};

export default EducationTile;
