import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-green-500 animate-fade-in" data-testid="footer">
      <nav className="animate-fade-in flex justify-center">
        <a
          href="https://www.linkedin.com/in/benjamin-a-hodge/"
          target="_blank"
          aria-label="LinkedIn Profile"
          className="py-5 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
        >
          LinkedIn
        </a>
        <a
          href="https://github.com/bahodge"
          target="_blank"
          aria-label="GitHub Profile"
          className="py-5 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
        >
          Github
        </a>
        <a
          href="https://gitlab.com/users/benhodge_plusone"
          target="_blank"
          aria-label="GitLab Profile"
          className="py-5 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
        >
          GitLab
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
