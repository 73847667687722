import React, { useState } from "react";

import ROUTES from "../../constants/routes";

import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    // <!-- navbar goes here -->
    <nav className="bg-green-500 animate-fade-in mb-10">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-4">
            {/* <!-- logo --> */}
            <div>
              <Link
                onClick={() => setMenuOpen(false)}
                to={ROUTES.ROOT}
                className="flex items-center py-5 px-2 text-white hover:text-gray-200 transition ease-in-out duration-300"
              >
                <svg
                  className="md:flex"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 64 64"
                >
                  <path
                    id="Selection #2"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    d="M 0.00,0.00
                    M 11.00,3.00
                    C 11.00,3.00 6.00,3.00 6.00,3.00
                      6.00,3.00 6.00,53.00 6.00,53.00
                      6.00,53.00 31.00,53.00 31.00,53.00
                      31.00,53.00 31.00,45.00 31.00,45.00
                      31.00,45.00 36.00,45.00 36.00,45.00
                      36.00,45.00 36.00,53.00 36.00,53.00
                      36.00,53.00 41.00,53.00 41.00,53.00
                      41.00,53.00 41.00,35.00 41.00,35.00
                      41.00,35.00 57.00,35.00 57.00,35.00
                      57.00,35.00 57.00,53.00 57.00,53.00
                      57.00,53.00 61.00,53.00 61.00,53.00
                      61.00,53.00 61.00,29.00 61.00,29.00
                      61.00,29.00 41.00,29.00 41.00,29.00
                      41.00,29.00 41.00,3.00 41.00,3.00
                      41.00,3.00 36.00,3.00 36.00,3.00
                      36.00,3.00 36.00,39.00 36.00,39.00
                      36.00,39.00 31.00,39.00 31.00,39.00
                      31.00,39.00 31.00,29.00 31.00,29.00
                      31.00,29.00 11.00,29.00 11.00,29.00
                      11.00,29.00 11.00,3.00 11.00,3.00 Z
                    M 18.00,35.00
                    C 21.58,36.71 24.29,39.42 26.00,43.00
                      22.42,41.29 19.71,38.58 18.00,35.00 Z
                    M 15.00,37.00
                    C 18.58,38.71 21.29,41.42 23.00,45.00
                      19.42,43.29 16.71,40.58 15.00,37.00 Z
                    M 12.00,39.00
                    C 15.58,40.71 18.29,43.42 20.00,47.00
                      16.42,45.29 13.71,42.58 12.00,39.00 Z"
                  />
                </svg>

                <span className="font-bold ml-1">Ben Hodge</span>
              </Link>
            </div>

            {/* <!-- primary nav --> */}
            <div className="hidden md:flex items-center space-x-1">
              <Link
                to={ROUTES.ABOUT}
                className="py-5 px-3  text-white hover:text-gray-200 transition ease-in-out duration-300"
              >
                About
              </Link>
              <Link
                to={ROUTES.WORK}
                className="py-5 px-3  text-white hover:text-gray-200 transition ease-in-out duration-300"
              >
                Work
              </Link>
              <Link
                to={ROUTES.EDUCATION}
                className="py-5 px-3  text-white hover:text-gray-200 transition ease-in-out duration-300"
              >
                Education
              </Link>
            </div>
          </div>

          {/* <!-- mobile button goes here --> */}
          <div className="md:hidden mr-3 flex items-center z-50">
            <button onClick={() => setMenuOpen(!menuOpen)}>
              <svg
                className={`w-6 h-6 ${menuOpen ? "transform rotate-90" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* <!-- mobile menu --> */}
      <div className={`md:hidden z-0  ${menuOpen || "hidden"}`}>
        <div className="bg-green-500 absolute right-0 flex flex-col animate-slide-down">
          <Link
            onClick={() => setMenuOpen(false)}
            to={ROUTES.ABOUT}
            className="py-2 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
          >
            About
          </Link>
          <Link
            onClick={() => setMenuOpen(false)}
            to={ROUTES.WORK}
            className="py-2 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
          >
            Work
          </Link>
          <Link
            onClick={() => setMenuOpen(false)}
            to={ROUTES.EDUCATION}
            className="py-2 px-3 text-white hover:text-gray-200 transition ease-in-out duration-300"
          >
            Education
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
