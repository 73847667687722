import React from "react";

export type Entry = {
  content: string;
  title: string;
  company: string;
  start: string;
  end: string;
  link?: string;
};

export type WorkTileProps = {
  entry: Entry;
};

const WorkTile: React.FC<WorkTileProps> = ({ entry }) => {
  return (
    <article className="flex flex-col md:flex-row justify-between">
      <div className="md:w-1/2">
        <h3 className="text-xl">{entry.title}</h3>
        <div className="text-lg">
          {entry.link ? (
            <a
              data-testid="company-link"
              href={entry.link}
              aria-label="to company site"
              target="_blank"
              className="underline hover:text-gray-400 transition ease-in-out duration-300"
            >
              <span>{entry.company}</span>
            </a>
          ) : (
            <span>{entry.company}</span>
          )}
        </div>
        <div>
          {entry.start} {"->"} {entry.end}
        </div>
      </div>

      <div className="mt-1 md:mt-0 md:w-1/2">{entry.content}</div>
    </article>
  );
};

export default WorkTile;
