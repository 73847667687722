import React from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ROUTES from "./constants/routes";

import Layout from "./components/Layout";

import AboutPage from "./pages/AboutPage";
import RootPage from "./pages/RootPage";
import WorkPage from "./pages/WorkPage";
import EducationPage from "./pages/EducationPage";

const trackingId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "google-analytics-id-unset";
ReactGA.initialize(trackingId);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={ROUTES.ABOUT} element={<AboutPage />} />
          <Route path={ROUTES.WORK} element={<WorkPage />} />
          <Route path={ROUTES.EDUCATION} element={<EducationPage />} />
          <Route path={ROUTES.ROOT} element={<RootPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
