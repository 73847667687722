import React, { useEffect } from "react";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const AboutPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, []);

  return (
    <article data-testid="about-page" className="animate-fade-in flex flex-col">
      <div className="mb-5">
        <h3 className="text-4xl font-bold">
          I like learning about new domains
        </h3>
        <p className="my-2">
          I've been pretty lucky to get to work with some really great companies
          in the local San Antonio, TX area. I've worked on a pretty diverse set
          of projects ranging from back-office real estate accounting to
          connecting humans to robots through the internet. I've been developing
          web apps and websites since 2018 but programming since 2014.
        </p>

        <p className="my-2">
          I'm an avid learner of all things and enjoy learning new languages in
          my free time. By no means am I a polyglot, but I do find it
          interesting how different languages solve problems. Some are designed
          with developer expressiveness in mind like Ruby, others for memory
          safety like rust. I'm currently in the depths of teaching myself Go on
          a personal project but some other languages watch out for are{" "}
          <a
            className="underline hover:text-gray-400 transition ease-in-out duration-300"
            href="https://elixir-lang.org/"
            target="_blank"
            aria-label="Elixir Programming Language"
          >
            Elixir
          </a>{" "}
          &amp;{" "}
          <a
            className="underline hover:text-gray-400 transition ease-in-out duration-300"
            href="https://crystal-lang.org/"
            target="_blank"
            aria-label="Crystal Programming Language"
          >
            Crystal
          </a>
          . There are a ton of technologies that are amazing in so many ways,
          and when used as intended they can solve a bunch of problems across
          many domains.
        </p>
      </div>

      <div>
        <h3 className="text-4xl font-bold">
          I'm most comfortable in the backend
        </h3>
        <p className="my-2">
          Yeah sure having great UI/UX plays a major role in the success of your
          project but the allure of centering divs has faded for me. With a new
          frontend framework coming out every month or so, it's hard to keep up
          with the latest trends. Each of these frameworks/libraries are just
          trying to solve the same problem of getting information to the user
          faster. My interests have shifted to solving scaling problems,
          distributed computation problems and reliability problems. I like
          ensuring that the information is available for the frontends to serve
          to the users.
        </p>
      </div>
    </article>
  );
};

export default AboutPage;
