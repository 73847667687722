import React, { useEffect } from "react";

import EducationTile, { Entry } from "../components/EducationTile";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
const EducationPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, []);

  const entries: Entry[] = [
    {
      degree: "Certified ScrumMaster (CSM)",
      type: "Certificate",
      schoolName: "ScrumAlliance",
      start: "2020",
      end: "2022",
      link: "https://www.scrumalliance.org/",
      description:
        "A Scrum Master is the Scrum Team member tasked with fostering an effective and productive working environment and guiding others to understand Scrum values, principles and practices.",
    },
    {
      degree: "Google Cloud - Associate Cloud Engineer",
      type: "Certificate",
      schoolName: "Google Cloud",
      start: "2020",
      end: "2022",
      link: "https://cloud.google.com/certification/cloud-engineer",
      description:
        "An Associate Cloud Engineer deploys applications, monitors operations, and manages enterprise solutions.",
    },
    {
      degree: "Fullstack Web Development - Java",
      type: "Certificate",
      schoolName: "Codeup",
      start: "2018",
      end: "2018",
      link: "https://www.codeup.com",
      description:
        "Fullstack full immersion course where we focused on creating relevant web applications using Java, Spring, vanilla JavaScript & frameworks like Bootstrap.",
    },
    {
      degree: "Game Design",
      type: "B.S.",
      schoolName: "Fullsail University",
      start: "2014",
      end: "2017",
      link: "https://www.fullsail.edu",
      description:
        "Studied Game Design using industry standard tools like Unity3D, Unreal Engine, C#, JavaScript, C++ and other game development technologies. Learned the fundamentals of programming, team programming, version control and test driven development. Authored video and board games from an analytical perspective to better understand human engagement, perspective and psychology of games. Focused mostly on using mathematics, probability and game theory to create memorable experiences for players.",
    },
  ];

  return (
    <div data-testid="education-page" className="animate-fade-in flex flex-col">
      <article className="flex flex-col justify-start">
        {entries.map((entry, idx) => (
          <div className="mt-1 md:h-screen-20 md:min-h-13" key={idx}>
            <EducationTile entry={entry} />
            {/* spacer */}
            <div className="flex bg-green-400 h-0.5 justify-center md:opacity-0 mt-3 mb-3" />
          </div>
        ))}
      </article>
    </div>
  );
};

export default EducationPage;
