import React from "react";

import Navbar from "../Navbar";
import Footer from "../Footer";

type LayoutProps = {
  children?: JSX.Element | JSX.Element[];
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      data-testid="layout"
      className="h-screen flex flex-col justify-between"
    >
      <Navbar />

      {/* main container */}
      <div className="container max-w-6xl mx-auto px-4 mb-5">
        <main data-testid="main">{children}</main>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
