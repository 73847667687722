import React, { useEffect } from "react";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const RootPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, []);

  return (
    <section
      data-testid="root-page"
      // className="animate-fade-in flex flex-col"

      className="animate-fade-in flex flex-col md:flex-row md:justify-between md:items-center"
    >
      <div className="md:max-w-sm">
        <h1 className="font-bold text-4xl">Hi, I'm Ben.</h1>
        <div className="text-xl">
          <p>
            I make maintainable enterprise web applications for innovative
            projects.
          </p>
        </div>
      </div>

      {/* spacer */}
      <div className="h-5 md:hidden" />

      <article className="md:max-w-md" data-testid="root-description">
        <p className="my-2">
          I'm a web developer & a US Army veteran at a computer vision startup
          called{" "}
          <a
            className="underline hover:text-gray-400 transition ease-in-out duration-300"
            href="https://www.plusonerobotics.com"
            target="_blank"
            aria-label="plusonerobotics.com"
          >
            Plus One Robotics
          </a>{" "}
          in San Antonio, TX.
        </p>
      </article>
    </section>
  );
};

export default RootPage;
